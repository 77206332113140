exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-hits-index-js": () => import("./../../../src/pages/hits/index.js" /* webpackChunkName: "component---src-pages-hits-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-misc-index-js": () => import("./../../../src/pages/misc/index.js" /* webpackChunkName: "component---src-pages-misc-index-js" */),
  "component---src-pages-music-bts-index-js": () => import("./../../../src/pages/music/bts/index.js" /* webpackChunkName: "component---src-pages-music-bts-index-js" */),
  "component---src-pages-music-index-js": () => import("./../../../src/pages/music/index.js" /* webpackChunkName: "component---src-pages-music-index-js" */)
}

